<template>
  <div>
    <p class="pl-3 pb-3" style="font-weight: bold;margin-bottom: 8px;">{{ $t(`label.searchDate`) }}</p>
    <v-row v-if="$vuetify.breakpoint.xsOnly" class="pb-4">
      <v-col cols="6">
        <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              clearable
              @click:clear="clearFilter()"
              outlined
              class="date-search ml-3 elevation-0"
              hide-details="true"
              v-on="on"
              append-icon="search"
            ></v-text-field>
          </template>
          <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-else class="pb-4">
      <v-col cols="4">
        <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y style="border-radius: 15px;">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" outlined class="date-search ml-3 elevation-0" hide-details="true" v-on="on"></v-text-field>
          </template>
          <v-date-picker color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-btn @click="filterListByDate()" class="searchBtn">
          search
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12">
          <v-data-table light :headers="detailHeaders" :items="filteredDownlineList" class="elevation-0 history-table" :hide-default-footer="true" hide-default-header :items-per-page="-1">
            <template v-slot:header="{ props }">
              <tr style="padding-bottom: 12px;">
                <th
                  class="history-table-header text-start black--text"
                  style="background-color:#F3F5F6;"
                  v-for="(head, index) in props.headers"
                  :key="head.text"
                  :class="{ 'first-cell': index === 0, 'last-cell': index === props.headers.length - 1 }"
                >
                  <span v-if="head.text !== 'dateSearch'">
                    {{ $t(`label.${head.text}`) }}
                    <span v-if="head.sortable">
                      <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">mdi-chevron-down</v-icon>
                      <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">mdi-chevron-up</v-icon>
                    </span>
                  </span>
                </th>
              </tr>
            </template>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index" :class="getRowClass(index)">
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.membercode }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ item.created_at }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ decimalTruncate(item.required_deposit, 2) }} / {{ decimalTruncate(item.current_deposit, 2) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ decimalTruncate(item.required_valid_bet, 2) }} / {{ decimalTruncate(item.current_valid_bet, 2) }}
                </td>
                <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  {{ decimalTruncate(item.bonus_amount, 2) }}
                </td>
                <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                  <span v-if="item.process_status == 4">{{ $t(`affiliateBonusStatus.fail`) }}</span>
                  <span v-if="item.process_status == 3">{{ $t(`affiliateBonusStatus.success`) }}</span>
                  <span v-if="item.process_status == 2">{{ $t(`affiliateBonusStatus.pending`) }}</span>
                  <span v-if="item.process_status == 1">{{ $t(`affiliateBonusStatus.process`) }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="filteredDownlineList.length == 0" class="text-center pt-0 pb-8">
          <label class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import deleteIcon from '@/assets/svg/delete-icon.svg'
import { formValidator, uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import cloneDeep from 'lodash/cloneDeep'
import { AFFILIATE_DOWNLINE_DETAIL, AFFILIATE_DOWNLINE_LIST } from '@/store/affiliate.bonus.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import filter from 'lodash/filter'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import map from 'lodash/map'

export default {
  name: 'downlineHistory',
  components: {
    deleteIcon
  },
  data: () => ({
    dateMenu: false,
    picker: {},
    dateRange: {
      startDate: '',
      endDate: ''
    },
    detailHeaders: [
      {
        text: 'friend',
        align: 'start',
        sortable: false
      },
      {
        text: 'linkedTime',
        sortable: true
      },
      {
        text: 'totalDeposit',
        sortable: false
      },
      {
        text: 'turnover',
        sortable: false
      },
      {
        text: 'bonusAmount',
        sortable: true
      },
      {
        text: 'status',
        sortable: true
      }
    ],
    localdate: {
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 0
    },
    dateOfBirthMenu: false,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    headers: [
      {
        text: 'friend',
        align: 'start',
        sortable: false,
        value: 'member_id'
      },
      {
        text: 'linkedTime',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'totalDeposit',
        value: 'register_date_time',
        sortable: true,
        sortBy: 'asc'
      },
      {
        text: 'turnover',
        value: 'login_date_time',
        sortable: false
      },
      {
        text: 'bonus',
        value: 'last_deposit_date_time',
        sortable: false
      },
      {
        text: 'status',
        value: 'level',
        sortable: true,
        sortBy: 'asc'
      }
    ],
    downlines: [],
    filteredDownlines: [],
    filteredDownlineList: []
  }),
  created() {
    this.metaTag()
    this.getDownlineList()
  },
  computed: {
    getRowClass() {
      return index => {
        return index % 2 === 0 ? 'even-row' : 'odd-row'
      }
    },
    getRowClass2() {
      return index => {
        return index % 2 === 0 ? 'even-row2' : 'odd-row2'
      }
    },
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    affiliateDownlineList() {
      return this.$store.state.affiliateBonus.affiliateDownlineList.data
    }
  },
  watch: {
    affiliateDownlineList() {
      this.filteredDownlineList = cloneDeep(this.$store.state.affiliateBonus.affiliateDownlineList.data)
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    getDownlineDetail() {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    },
    getDownlineList() {
      let filter = {
        start_time: this.dates[0],
        end_time: this.dates[1],
        page: this.selectedPage,
        size: 10
      }
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_LIST}`, { filter })
    },
    redirectDownLineReport() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_DOWNLINE_REPORT,
        params: {
          lang: this.$route.params.lang
        }
      })
    },
    decimalTruncate(num, fixed) {
      if (num == null) {
        num = 0
      }
      let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
      return parseFloat(num.toString().match(re)[0])
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        setTimeout(function() {
          this.dateOfBirthMenu = false
        }, 500)
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    sortTableList(obj) {
      let sortBy = filter(this.headers, function(d) {
        return d.sortable
      })
      if (obj) {
        let selected = find(sortBy, function(d) {
          if (d.value == obj.value) {
            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
            return true
          }
        })
      }
      this.downlineList = orderBy(this.downlineList, map(sortBy, 'value'), map(sortBy, 'sortBy'))
      this.filteredDownlines = orderBy(this.filteredDownlines, map(sortBy, 'value'), map(sortBy, 'sortBy'))
    },
    filterListByDate() {
      if (this.dates[0] && this.dates[1]) {
        this.getDownlineList()
      }
    },
    clearFilter() {
      this.dates = []
      this.filterListByDate()
    }
  }
}
</script>

<style lang="scss">
.first-cell {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.last-cell {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.v-menu__content.menuable__content__active {
  min-width: 290px !important;
}
.even-row {
  background-color: #f2f2f2;
}
.even-row2 {
  background-color: #d5d5d5;
  margin-left: -16px;
  margin-right: -16px;
}
.odd-row {
  background-color: white;
}
.history-table {
  white-space: nowrap;

  .history-table-header {
    background: var(--v-background-darken1);
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 10px 16px;

    &:not(:last-child) {
      border-style: none dashed none none;
      border-color: rgba(255, 255, 255, 0.9);
      border-width: thin;
    }
  }
}
.daterangepicker .calendars-container {
  min-width: 290px;
  max-width: 290px;
  top: 600px;
  left: 918px;
  transform-origin: left top;
  z-index: 8;
}
.date-search {
  .v-input__slot {
    min-height: 40px !important;
  }
  .v-input__append-inner {
    margin-top: 8px;
  }

  &.v-text-field--outlined fieldset {
    border-color: #d3d3d3 !important;
  }
}
.referral-table-header {
  color: #acacac;
}
.member-id-btn {
  background-color: transparent !important;
  span {
    color: #3a3a3a !important;
    text-decoration: underline;
  }
}
.report-expansion-panel.v-expansion-panels {
  font-size: 14px !important;
  color: #3a3a3a !important;
  .v-expansion-panel-header {
    font-size: 14px !important;
    color: #3a3a3a;
    padding: 5px;
  }
  .expansion-panel-content {
    margin: 0px 15px;
    border: 1px solid #3a3a3a;
    border-radius: 5px;
    background-color: #ebebeb;
    .v-expansion-panel-content__wrap {
      padding: 10px;
    }
  }
}
</style>
